<template>
  <div class="medical-detail">
    <el-form>
      <el-form-item label="患者姓名:">
        {{ detailInfo.patientName }}
      </el-form-item>
      <el-form-item label="手机号码:">
        {{ detailInfo.mobile }}
      </el-form-item>
      <el-form-item label="上传ID:">
        {{ detailInfo.id }}
      </el-form-item>
      <el-form-item label="上传时间:">
        {{ detailInfo.uploadedAt }}
      </el-form-item>
      <div class="imgStyle">
        <div class="selectProject">
          <el-form-item label="注意:">
            <span>请先勾选随访项目</span>
          </el-form-item>
          <el-form-item label="随访节点:">
            <span>{{ detailInfo.startDate }}-{{ detailInfo.endDate }}</span>
          </el-form-item>
          <el-form-item label="计划医生:">
            <span>{{ detailInfo.doctorName }}</span>
          </el-form-item>
          <!-- <el-checkbox-group v-model="detailInfo.planInterviewDetails"> -->
          <div class="checkBoxStyle">
            <div v-for="item in detailInfo.planInterviewDetails" :key="item.id">
              <el-checkbox
                v-model="item.isReportUploaded"
                :disabled="detailInfo.status !== 0 || item.disabled"
              >
                {{ item.projectName }}
              </el-checkbox>
            </div>
          </div>
          <!-- </el-checkbox-group> -->
        </div>
        <div>
          <h2>{{ preAuditStateDic[detailInfo.status] }}</h2>
          <el-form-item label="共计:"> {{ imgSize }}张 </el-form-item>
          <!-- <el-carousel>
            <el-carousel-item
              v-for="(item,index) in detailInfo.reportImages"
              :key="index"
            >
              <el-image
                :src="item"
                class="img"
                :preview-src-list="detailInfo.reportImages"
              />
            </el-carousel-item>
          </el-carousel> -->
          <!-- <el-carousel>

            v-for="(item,index) in detailInfo.reportImages"
            :key="index"
          >
            <el-image
              v-if="index===0"
              :src="item"
              class="img"
              :preview-src-list="detailInfo.reportImages"
            />
          </div> -->
          <!-- </el-carousel> -->
          <el-image
            v-if="detailInfo.reportImages.length > 0"
            :src="detailInfo.reportImages[0]"
            class="img"
            fit="scale-down"
            :preview-src-list="detailInfo.reportImages"
          />
          <div class="btn">
            <el-button
              type="primary"
              :disabled="detailInfo.status !== 0"
              @click="jumpDialog('FAIL')"
            >
              驳回
            </el-button>
            <el-button
              type="primary"
              :disabled="detailInfo.status !== 0"
              @click="jumpDialog('SUCCESS')"
            >
              审核通过
            </el-button>
            <el-button
              type="primary"
              :disabled="noNextReport"
              @click="getNextMedicalData"
            >
              下份报告
            </el-button>
            <el-button type="primary" @click="$router.back()"> 返回 </el-button>
          </div>
        </div>
      </div>
    </el-form>
    <el-dialog :title="titleType" :visible.sync="showDialog" width="35%">
      <h3
        v-if="titleType === '审核通过'"
        style="margin-bottom: 20px; text-align: center"
      >
        确定当前报告通过审核吗？
      </h3>
      <el-form
        v-if="showDialog && titleType === '驳回'"
        ref="form"
        label-position="right"
        :model="form"
        :rules="rules"
        label-width="160px"
        @submit.native.prevent
      >
        <el-form-item label="驳回原因:" prop="remark">
          <el-select v-model="form.remark" placeholder="请选择驳回原因">
            <el-option
              v-for="item in failReasonList"
              :key="item.value"
              :value="item.text"
              :label="item.text"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="audit(titleType)">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { preAuditStateDic } from '@/utils/component/publicList.js';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
export default {
  name: 'MedicalDetail',
  data() {
    this.failReasonList = [
      { text: '比较模糊', value: 1 },
      { text: '存在缺页', value: 2 },
      { text: '不是出院小结/复查检验记录', value: 3 },
    ];
    return {
      preAuditStateDic: preAuditStateDic, // 字典
      detailInfo: {
        planInterviewDetails: [],
        reportImages: [],
      }, // 详情信息
      imgSize: 0, // 图片的张数
      titleType: '', // 弹框标题
      showDialog: false, // 弹框默认不展示,
      allProject: [], // 随访项目列表
      noNextReport: false, // 是否有下一份报告
      nextId: '', // 下一份报告的id
      idList: [], // 查询的id列表
      currentIndex: this.$route.query.index, // 当前数据的id
      form: {
        remark: '',
      },
      rules: {
        remark: [
          { required: true, message: '请选择驳回原因', trigger: 'blur' },
        ],
      },
      selectIdList: [], // 已选项目id列表
    };
  },
  computed: {
    ...mapState('medicalHistoryManage', {
      medicalHistoryList: (state) => state.medicalHistoryList,
    }),

    currentId() {
      return this.$route.query.id;
    },
    listParams() {
      return JSON.parse(this.$route.query.params) || {};
    },
  },
  async mounted() {
    await this.getQueryReportIdList();
    this.getFollowUpDetail(this.currentId);
  },
  methods: {
    ...mapActions('medicalHistoryManage', ['medicalPageQuery']),
    getFollowUpDetail(id) {
      this.$api.queryReportDetail({ id: id }).then((res) => {
        if (res.code === 0) {
          res.data.planInterviewDetails.map((v) => {
            return (v.isReportUploaded = v.isReportUploaded === 1);
          });

          this.imgSize = res.data.reportImages.length;
          // 已经勾选过的项目
          res.data.planInterviewDetails
            .filter((v) => v.isReportUploaded)
            .forEach((v1) => {
              this.selectIdList.push(v1.id);
            });
          res.data.planInterviewDetails.forEach((v) => {
            v.disabled = false;
            if (v.isReportUploaded) {
              v.disabled = true;
            }
          });
          this.detailInfo = res.data;
          console.log(this.selectIdList, this.detailInfo, 'this.selectIdList');
          if (this.idList.length <= this.currentIndex + 1) {
            this.noNextReport = true;
          }
        }
      });
    },
    // 查看下一份报告
    getNextMedicalData() {
      const { idList } = this;
      this.getFollowUpDetail(idList[this.currentIndex + 1]);
      this.currentIndex += 1;
    },
    // 跳转至弹框
    jumpDialog(type) {
      this.titleType = type === 'SUCCESS' ? '审核通过' : '驳回';
      this.showDialog = true;
    },
    // 点击弹框确定
    audit(titleType) {
      const { currentId, form, detailInfo, selectIdList } = this;
      console.log(detailInfo, 'de');
      var list = [];
      detailInfo.planInterviewDetails
        .filter((v) => v.isReportUploaded)
        .forEach((v1) => {
          list.push(v1.id);
        });

      console.log(list, 'list');
      var projectIds = [];
      list.forEach((v) => {
        console.log(v, 'v');
        if (selectIdList.indexOf(v) === -1) {
          projectIds.push(v);
        }
      });
      const params = {
        id: currentId,
        remark: titleType === '驳回' ? form.remark : '',
        status: titleType === '驳回' ? 4 : 3,
        projectIds: projectIds,
      };
      if (titleType === '驳回') {
        this.$refs['form'].validate((valid) => {
          if (!valid) {
            return false;
          }
        });
      }
      this.$api.auditReport(params).then((res) => {
        if (res.code) {
          this.showDialog = false;
          this.$message.success(
            titleType === '驳回' ? '驳回成功' : '审核通过成功'
          );
          this.form.remark = '';
          this.getFollowUpDetail(this.currentId);
        }
      });
    },
    // 查询随访项目列表
    getQueryReportIdList() {
      console.log('1356');
      console.log(this.listParams, 'listParams');
      const { listParams, currentIndex } = this;
      const param = {
        ...listParams,
        startDate:
          listParams.time !== '' && listParams.time !== null
            ? moment(listParams.time[0]).format('YYYY-MM-DD')
            : '',
        endDate:
          listParams.time !== '' && listParams.time !== null
            ? moment(listParams.time[1]).format('YYYY-MM-DD')
            : '',
      };
      this.$api.queryReportIds(param).then((res) => {
        if (res.data.length === 0 || !res.data) {
          this.noNextReport = true;
        } else {
          console.log(res.data, currentIndex, 'data');
          this.idList = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}
.imgStyle {
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  margin-bottom: 20px;
  margin-top: 50px;
  // padding-right: 100px;
  .selectProject {
    margin-right: 50px;
  }
  .el-carousel {
    // width: 500px !important;
    margin-bottom: 20px;
  }
  .img {
    width: 300px;
    height: 300px;
  }
}
.checkBoxStyle {
  width: 400px;
  height: 200px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  // justify-content:space-around;
  padding: 10px;
  .el-checkbox {
    margin-right: 20px;
  }
}
.btn {
  margin-top: 20px;
  text-align: center;
}
</style>
