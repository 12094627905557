const accountStatusList = [
    { text: '全部', value: 'all' },
    { text: '正常', value: false },
    { text: '冻结', value: true }
];
const drugStatusList = [
    { text: '全部', value: '' },
    { text: '有效', value: true },
    { text: '无效', value: false }
];
const drugStatus = [
    { text: '有效', value: true },
    { text: '无效', value: false }
];
const symbolList = [
    { text: '全部', value: '' },
    { text: '西药', value: '01' },
    { text: '中成药', value: '02' },
    { text: '草药饮片', value: '03' },
    { text: '草药颗粒', value: '04' }
];
const symbol = [
    { text: '全部', value: '' },
    { text: '西药', value: '01' },
    { text: '中成药', value: '02' },
    { text: '草药饮片', value: '03' },
    { text: '草药颗粒', value: '04' }
];
const symbolDic = {
    '01': '西药',
    '02': '中成药',
    '03': '草药饮片',
    '04': '草药颗粒'
};
const confirmStatusList = [
    { text: '全部', value: '' },
    { text: '未抢单', value: false },
    { text: '已抢单', value: true },
    { text: '已取消', value: 'isCancel' }
];
const authenticationStatusList = [
    { text: '全部', value: 'all' },
    { text: '待认证', value: 'WAIT_AUTH' },
    { text: '认证中', value: 'IN_AUTH' },
    { text: '认证失败', value: 'AUTH_FAIL' },
    { text: '认证成功', value: 'AUTH_SUCCESS' }
];
// 审核状态列表
const auditStatusList = [
    { text: '全部', value: '' },
    { text: '医生创建', value: 'INIT' },
    { text: '待审核', value: 'WAIT' },
    { text: '审核成功', value: 'SUCCESS' },
    { text: '审核失败', value: 'FAIL' }
];
// 审核状态字典
const auditStatusDic = {
    'INIT': '医生创建',
    'WAIT': '待审核',
    'SUCCESS': '审核成功',
    'FAIL': '审核失败'
};
// 开方CA签名状态列表
const createSignStatusList = [
    { text: '全部', value: '' },
    { text: '签名中', value: 'SIGNING' },
    { text: '待签名', value: 'WAIT' },
    { text: '签名成功', value: 'SUCCESS' },
    { text: '签名失败', value: 'FAIL' }
];
// 开方CA状态字典
const createSignStatusDic = {
    'SIGNING': '签名中',
    'WAIT': '待签名',
    'SUCCESS': '签名成功',
    'FAIL': '签名失败'
};
// 审方CA签名状态列表
const auditSignStatusList = [
    { text: '全部', value: '' },
    { text: '签名中', value: 'SIGNING' },
    { text: '待签名', value: 'WAIT' },
    { text: '签名成功', value: 'SUCCESS' },
    { text: '签名失败', value: 'FAIL' }
];
// 审方CA签名状态字典
const auditSignStatusDic = {
    'SIGNING': '签名中',
    'WAIT': '待签名',
    'SUCCESS': '签名成功',
    'FAIL': '签名失败'
};
// 机构盖章状态列表
const signatureStatusList = [
    { text: '全部', value: '' },
    { text: '待签章', value: 'WAIT' },
    { text: '签章成功', value: 'SUCCESS' },
    { text: '签章失败', value: 'FAIL' }
];
// 机构盖章状态字典
const signatureStatusDic = {
    'WAIT': '待签章',
    'SUCCESS': '签章成功',
    'FAIL': '签章失败'
};
const onlineStatusList = [
    { text: '全部', value: 'all' },
    { text: '上线', value: true },
    { text: '下线', value: false }
];
const orderStatusList = {
    'PRE_CONSULTATION': '待预诊',
    'WAIT_FOR_SERVER': '待服务',
    'SERVING': '服务中',
    'COMPLETED': '已完成',
    'CANCELED': '已取消',
    'all': '全部',
};
// 同步医网信状态列表
const trustStatusList = [
    { text: '全部', value: '' },
    { text: '同步中', value: 'WAIT' },
    { text: '同步成功', value: 'SUCCESS' },
    { text: '同步失败', value: 'FAIL' }
];
// 同步医网信状态字典
const trustStatusDic = {
    'WAIT': '同步中',
    'SUCCESS': '同步成功',
    'FAIL': '同步失败'
};
const infoList = [
    { text: '手机号', value: 'mobile' },
    { text: '邮箱', value: 'email' },
];
// 性别字典
const sexDic = {
    '0': '未知',
    '1': '男',
    '2': '女',
    '9': '未说明'
};
// 海南机构字典维护

const orgList = [
    { text: '政府公立', value: '1' },
    { text: '国企医院', value: '2' },
    { text: '国有与民营合资', value: '3' },
    { text: '民营', value: '4' },
    { text: '外资', value: '5' },

];
const orgTypeList = [
    { text: '综合门诊部', value: '1' },
    { text: '综合医院', value: '2' },
    { text: '中医医院', value: '3' },
    { text: '中西医结合医院', value: '4' },
    { text: '民族医院', value: '5' },
    { text: '专科医院', value: '6' },
    { text: '康复医院', value: '7' },
    { text: '妇幼保健院', value: '8' },
];
const orgLevelList = [
    { text: '一级', value: '1' },
    { text: '二级', value: '2' },
    { text: '三级', value: '3' },
    { text: '未定级', value: '4' },

];
const orgRankList = [
    { text: '特等', value: '1' },
    { text: '甲等', value: '2' },
    { text: '乙等', value: '3' },
    { text: '丙等', value: '3' },
    { text: '未定等次', value: '5' },
];
const doctorPracticeLevelList = [
    { text: '执业医师', value: '1' },
    { text: '执业助理医师', value: '2' },
];
const isNoList = [
    { text: '否', value: false },
    { text: '是', value: true },
];
const sexList = [
    { geName: '男', geCode: '1' },
    { geName: '女', geCode: '2' },
];
const healthConditionList = [
    { text: '健康', value: '0' },
    { text: '良好', value: '1' },
    { text: '一般', value: '2' },
];
const educationList = [
    { text: '中专', value: '0' },
    { text: '专科', value: '1' },
    { text: '本科', value: '2' },
    { text: '硕士', value: '3' },
    { text: '博士', value: '4' },
];
// 时间类型列表
const timeTypeList = [
    { text: '全部', value: 'all' },
    { text: '上午', value: 'MORNING' },
    { text: '下午', value: 'AFTERNOON' },
    { text: '晚上', value: 'NIGHT' },

];
// 时间类型字典
const timeTypeDic = {
    'MORNING': '上午',
    'AFTERNOON': '下午',
    'NIGHT': '晚上'
};
// 医生退诊理由字典
const refuseReasonDic = {
    'OUT_OF_RANGE': '您的问诊超出范围，建议咨询其他医生',
    'INCONVENIENCE': '暂时不方便咨询，建议咨询其他医生',
    'OTHER': '其他'
};
// 配送状态列表
const deliveryStateList = [
    { text: '全部', value: 'all' },
    { text: '待配送', value: 'wait4delivere' },
    { text: '配送中', value: 'delivering' },
    { text: '已到达', value: 'completed' },
    { text: '已作废', value: 'deleted' },
];
const deliveryStateDIC = {
    'wait4delivere': '待配送',
    'delivering': '配送中',
    'completed': '已到达',
    'deleted': '已作废'
};
// 预审状态列表
const preAuditStateList = [
    { text: '全部', value: '' },
    { text: '待审核', value: 0 },
    { text: '审核通过', value: 3 },
    { text: '已驳回', value: 4 },
];
// 预审字典
const preAuditStateDic = {
    0: '待审核',
    3: '审核通过',
    4: '已驳回'
};
// 上传类型字典
const uploadTypeDic = {
    1: '出院小结',
    2: '随访报告',
    3: '检测报告'
};
const medicalAuditStateList = [
    { text: '全部', value: '' },
    { text: '待审核', value: 5 },
    { text: '审核通过', value: 0 },
    { text: '已驳回', value: 2 },
    { text: '图片违规', value: 1 },
];
const medicalAuditStateDic = {
    5: '待审核',
    0: '审核通过',
    2: '已驳回',
    1: '图片违规'
};
const medicalType = {
    1: '出院小结',
    2: '检验报告',
    4: '检查报告',
    9: '其他'
};
// 迁移状态列表
const migrateStatusList = [
    { text: '迁移中', value: 0 },
    { text: '成功', value: 1 },
    { text: '失败', value: 2 },
];
// 迁移状态字典
const migrateDic = {
    0: '迁移中',
    1: '成功',
    2: '失败'
};
// 触发类型列表
const triggerTypeList = [
    { text: '正常调用', value: 0 },
    { text: '定时调度', value: 1 },
    { text: '手动触发', value: 2 },
];
// 触发类型字典
const triggerTypeDic = {
    0: '正常调用',
    1: '定时调度',
    2: '手动触发'
};
export {
    accountStatusList,
    authenticationStatusList,
    onlineStatusList,
    confirmStatusList,
    orderStatusList,
    drugStatusList,
    drugStatus,
    symbolList,
    symbol,
    symbolDic,
    auditStatusList,
    auditStatusDic,
    createSignStatusList,
    createSignStatusDic,
    auditSignStatusList,
    auditSignStatusDic,
    signatureStatusList,
    signatureStatusDic,
    trustStatusList,
    trustStatusDic,
    infoList,
    sexDic,
    orgList,
    orgTypeList,
    orgLevelList,
    orgRankList,
    doctorPracticeLevelList,
    isNoList,
    sexList,
    healthConditionList,
    educationList,
    timeTypeList,
    timeTypeDic,
    refuseReasonDic,
    deliveryStateList,
    deliveryStateDIC,
    preAuditStateList,
    preAuditStateDic,
    uploadTypeDic,
    medicalAuditStateList,
    medicalAuditStateDic,
    medicalType,
    migrateStatusList,
    migrateDic,
    triggerTypeList,
    triggerTypeDic
};
/**
 * 将参数对象转为外链并返回
 * @param {path,query}
 * path 前缀路径
 * query 参数
 */
export function formatWebUrl(params) {
    const queryArr = [];
    for (const key in params.query) {
        queryArr.push(`${key}=${params.query[key]}`);
    }
    const queryStr = queryArr.join('&');
    return `${params.path}?${queryStr}`;
}
